import React, { Fragment } from 'react';
import ClubhouseOutside from '../media/EDRGC-Clubhouse-Front.jpg';
import ClubhouseHall from '../media/EDRGC-Clubhouse-Hall.jpg';
import ClubhouseKitchen from '../media/EDRGC-Clubhouse-Kitchen.jpg';
import ClubhouseLounge from '../media/EDRGC-Clubhouse-Members-Lounge.jpg';
import ClubhouseOutsideSmall from '../media/EDRGC-Clubhouse-Front-Skinny.jpg';
import IndoorRange from '../media/EDRGC-Indoor-Pistol-Range.jpg';
import IndoorStations from '../media/EDRGC-Indoor-Pistol-Stations.jpg';
import HundredYard from '../media/EDRGC-100Yard-Range.jpg';
import TwoHundredYard from '../media/EDRGC-200Yard-Range-Wide.jpg';
import ShotgunRange from '../media/EDRGC-Shotgun-Range.jpg';
import ArcheryRange from '../media/EDRGC-Archery-Range.jpg';
import PicnicArea from '../media/EDRGC-Picnic-Area.jpg';

interface IFacilitiesPageProps {

};

const FacilitiesPage: React.FC<IFacilitiesPageProps> = () => {
    return (
        <Fragment>
            <div className="bg-gray-300">
                <div className="mx-auto container text-2xl p-8">
                    A shooting sports facility that caters to everyone.
                </div>
            </div>
            <div className="mx-auto container p-8">
                Boasting one of the largest shooting facilities in Waterloo Region and Southern Ontario,
                since 1960 the Elmira and District Rod and Gun Club has provided a recreational venue
                for a variety of shooting sports including Benchrest, Pistol, Shotgun and Archery. Take a look
                at the amenities we offer all our members below.
            </div>
            <div className="container mx-auto px-8 flex-col space-y-8">
                <div className="shadow rounded flex flex-col bg-white">
                    <img src={ClubhouseOutside} className="block rounded-t object-cover h-64" alt="clubhouse exterior" />
                    <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600">Clubhouse</h1>
                    <p className="text-md p-8">
                        Our comfortable and spacious clubhouse contains everything our members need including a meeting area,
                        banquet hall, trophy case, kitchen &amp; bar, as well as a social area/break room complete with
                        competition-sized snooker table. The clubhouse is used for everything from our monthly membership
                        meetings, to hosting special events like gun shows and tournament registration. Naturally the fridge
                        is always stocked with an ample selection of reasonably-priced beverages; proceeds of which go directly
                        to supporting club facilities.
                    </p>
                    <div className="xs:flex xs:flex-col md:grid md:grid-cols-4 md:max-h-24 lg:max-h-48 overflow-hidden rounded-b border-t">
                        <img src={ClubhouseHall} className="object-scale-down rounded-bl" alt="clubhouse hall" />
                        <img src={ClubhouseKitchen} className="object-scale-down" alt="clubhouse kitchen" />
                        <img src={ClubhouseLounge} className="object-scale-down" alt="clubhouse lounge" />
                        <img src={ClubhouseOutsideSmall} className="object-scale-down rounded-br" alt="clubhouse exterior" />
                    </div>
                </div>
                <div className="shadow rounded flex xs:flex-col sm:flex-col lg:flex-row bg-white">
                    <img src={PicnicArea} className="xs:rounded-t lg:rounded-l" alt="picnic area" />
                    <div className="flex flex-col">
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600 rounded-tr">Picnic Area</h1>
                        <p className="text-md p-8">
                            The Picnic area is big enough to accommodate even very large groups with both covered and uncovered sections.
                            Best of all, it has an excellent view of the Archery range, allowing spectators to relax in style while still
                            enjoying the splendor of the great outdoors. Picnickers will also find the picnic area conveniently located
                            just a few hundred feet from the club’s covered horseshoe pits which makes for some cheap and easy entertainment
                            on a lazy afternoon.
                        </p>
                    </div>
                </div>
                <h1 className="text-3xl font-thin">Pistol</h1>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 xs:space-y-8 lg:space-y-0">
                    <div className="shadow rounded flex flex-col bg-white">
                        <div className="xs:flex xs:flex-col lg:grid lg:grid-cols-2 lg:h-48 lg:overflow-hidden">
                            <img src={IndoorRange} className="xs:rounded lg:rounded-tl object-scale-down object-center w-full" alt="indoor range" />
                            <img src={IndoorStations} className="lg:rounded-tr object-scale-down w-full" alt="indoor range stations" />
                        </div>
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600">Indoor Pistol Range</h1>
                        <p className="text-md p-8">
                            With space for six shooters, the Indoor Range is rated for .22 caliber long-guns as well as all non-jacketed
                            handgun rounds (lead only). Used extensively by the Canagagigue Shooting Association for practice and
                            competitions, the Indoor range is also very popular in the evenings. Equipped with a brand-new high-output
                            air purification system, operational and target spotlighting, handgun competition timing system, complimentary
                            paper targets and ample storage space for multiple firearms; the Indoor Range makes an excellent alternative
                            for shooters who prefer to ply their trade indoors, as well as during the winter months when the outdoor
                            ranges are a little on the chilly side.
                        </p>
                    </div>
                    <div className="shadow rounded flex flex-col bg-white">
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600 rounded-t">Outdoor Pistol Range</h1>
                        <p className="text-md p-8">
                            The Outdoor Pistol range is our newest offering, designed specifically to meet the needs of pistol/revolver shooters.
                            Shooters are able to fire from different distances (all shooters must be using the same distance), making it an excellent
                            resource for both recreational and competitive practice. Features include safe zone for working on firearms, movable
                            shooting benches.  Please note, use of the Outdoor Pistol range is restricted to members of the Canagagigue Shooting
                            Association (Pistol Club).
                        </p>
                    </div>
                </div>
                <h1 className="text-3xl font-thin">Benchrest</h1>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 xs:space-y-8 sm:space-y-8 lg:space-y-0">
                    <div className="shadow rounded flex flex-col bg-white">
                        <div className="h-64">
                            <img src={HundredYard} className="rounded-t object-cover h-full" alt="100 yd range" />
                        </div>
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600">100-Yard Range</h1>
                        <p className="text-md p-8">
                            The 100-yard Range is one of our most popular areas, able to meet the needs of just about anybody whether you’re a
                            new shooter just beginning the sport, a seasoned member stopping by with a few friends, or an experienced marksman
                            looking for a new challenge. With target bunkers stationed at 25, 50, 75 and 100 yards, it’s easy to spend an
                            afternoon with us using anything from shotguns to long-guns rated up to .50 caliber.  The 100 yard range is
                            completely covered, offering shooters some shade in the warmer months as well as a dry place to hone their skills
                            in even the the harshest downpour. Features include on-site brass collection, gun-racks for member use, metallic
                            silhouette targets, and on-site electrical power for various shooting and diagnostic gadgetry.
                        </p>
                    </div>
                    <div className="shadow rounded flex flex-col bg-white">
                        <img src={TwoHundredYard} className="rounded-t" alt="200 yd range" />
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600">200-Yard Range</h1>
                        <p className="text-md p-8">
                            Just a two-minute walk from the 100-yard range you’ll find our 200-yard range. With capacity for 15 shooters,
                            there’s always room to practice those challenging long distance shots that never fail to put a smile on your face.
                            Like it’s smaller brother, the 200-yard range is completely covered, providing members with a comfortable, dry
                            place with ample room to set up multiple long-guns, bi-pods, rangefinders and ammunition containers without
                            having to worry about the weather or space. Like the 100-yard, the 200-yard range also has on-site brass collection
                            and gun-racks, eliminating irritating problem of having to trek back to the car every time you want to switch guns
                            or drop off spent casings.
                        </p>
                    </div>
                </div>
                <h1 className="text-3xl font-thin">Shotgun</h1>
                <div className="shadow rounded flex xs:flex-col sm:flex-col lg:flex-row bg-white">
                    <img src={ShotgunRange} className="rounded-l" alt="shotgun stations" />
                    <div className="flex flex-col">
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600 rounded-tr">Shotgun Range</h1>
                        <p className="text-md p-8">
                            In addition to the standard handgun and long-gun, members are free to use the club’s dedicated shotgun booth and
                            electric remote-control clay-thrower to practice their clay-shooting skills in time for duck-season. Pull!
                        </p>
                    </div>
                </div>
                <h1 className="text-3xl font-thin">Archery</h1>
                <div className="xs:flex xs:flex-col lg:grid lg:grid-cols-2 lg:gap-8 xs:space-y-8 sm:space-y-8 lg:space-y-0">
                    <div className="shadow rounded flex flex-col bg-white">
                        <img src={ArcheryRange} className="rounded-t" alt="archery range" />
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600">Archery Range and Course</h1>
                        <p className="text-md p-8">
                            With arguably the largest and best-equipped archery range in Waterloo Region; Elmira District Rod and Gun Club is
                            proud to offer a superior Archery experience to members and competitors in our numerous well-attended Tournaments.
                            The Archery range presents members with a wide array of shooting targets ranging from traditional paper bullseyes,
                            to top of the line realistic 3D animal targets maintained by the club. With room for half a dozen archers, table space
                            for bows, arrows, and bags plus parking just footsteps away, archery is practiced at our club the way it was meant to be;
                            in a realistic outdoor environment free of distraction and cramped shooting spaces.
                        </p>
                    </div>
                    <div className="shadow rounded flex flex-col bg-white">
                        <h1 className="text-2xl px-8 py-4 bg-gray-400 text-white border-b-4 border-gray-600 rounded-t">Indoor Archery Range</h1>
                        <p className="text-md p-8">
                            Coming Soon!
                        </p>
                    </div>
                </div>
            </div>
            <div className="h-8"></div>
        </Fragment>
    );
};

export default FacilitiesPage;