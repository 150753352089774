import React, { useEffect } from 'react';

interface IAnnoucementsCardProps {

};

const AnnoucementsCard: React.FC<IAnnoucementsCardProps> = () => {

    useEffect(()=> {

        

    }, []);

    return (
        <div className="bg-white dark:bg-gray-900 rounded shadow">
            <div className="p-4 xs:p-2 border-b">
                <h1 className="md:text-xl font-semibold xs:text-md">Announcements</h1>
            </div>
            <div className="p-4 xs:p-2 font-semibold">
                <em className="font-light">There are no annoucements at this time.</em>
            </div>
        </div>
    );
};

export default AnnoucementsCard;