import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const PistolClubPage: React.FC<{}> = () => {
    return (
        <Fragment>
            <div className="bg-gray-200">
                <div className="container mx-auto p-8">
                    <h1 className="text-2xl">Canagagigue Shooting Association</h1>
                    <h2 className="text-xl">Pistol Club</h2>
                    <p className="mt-8">
                        The Canagagigue Shooting Associate (CSA) is a wholly distinct and separate entity from the Elmira &amp; District
                        Rod and Gun Club. In order to use restricted or prohibited class firearms at the facility, you must be a member
                        of the CSA.
                    </p>
                </div>
            </div>
            <div className="container mx-auto p-8 md:grid md:grid-cols-3 md:gap-8 xs:space-y-8">
                <div className="space-y-4">
                    <h3 className="text-xl font-semibold">Rates</h3>
                    <p>
                        The standard annual membership rate for the Canagagigue Shooting Association is $75/year; please note that this
                        is in addition to your membership fees for the Elmira &amp; Disctrict Rod and Gun Club. Fees collected for the
                        Canagagigue Shooting Association go towards the cost of maintaining your ATT, while those collected for the
                        Elmira &amp; District Rod and Gun Club take care of the ranges and facilities.
                    </p>
                </div>
                <div className="space-y-4">
                    <h3 className="text-xl font-semibold">Requirements</h3>
                    <p>
                        In order to be accepted into the Canagagigue Shooting Association, prospective members are required to first be a
                        member in good standing of the Elmira &amp; District Rod and Gun Club for a minimum of six months.&nbsp;
                        <Link to="/join">Click here</Link> to review the membership process for EDRGC. Once this term has been completed,
                        any EDRGC member interested in joining CSA simply submits their application, and pays their dues on acceptance.
                    </p>
                </div>
                <div className="space-y-4">
                    <h3 className="text-xl font-semibold">Membership</h3>
                    <p>
                        Once a member has completed six months with the EDRGC, they need only attend a CSA meeting, and submit their
                        Application form. Note, this is not the same form used for EDRGC! Once the form is fully completed, let the
                        Executive know you’d like to join, and a CSA meeting will be held at the conclusion of the EDRGC meeting.
                    </p>
                </div>
            </div>
            <div className="container mx-auto p-8">
                <div className="bg-white rounded p-8 border-2 border-red-700">
                    In order to transport restricted or prohibited class firearms legally in Ontario, residents must be in possession of a
                    Federal ATT (Authorization To Transport) for target shooting. If you are a member in good standing who has successfully
                    passed the safety courses and do not already have an ATT from another club, the Canagagigue Shooting Association Secretary
                    will apply for your ATT. This process can take several weeks so apply early to avoid having to wait.
                </div>
            </div>
            <div className="container mx-auto p-8">
                <a className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded w-96 space-x-4 inline-flex"
                    href="https://files.elmirarodandgun.org/application_pistol.pdf">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                    <div className="flex flex-col">
                        <span className="font-semibold">Download Application Form</span>
                        <span className="font-normal">application_pistol.pdf</span>
                    </div>
                </a>
            </div>
        </Fragment>
    );
};

export default PistolClubPage;