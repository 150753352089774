import React from 'react';

interface IEventsPageProps {

};

const EventsPage: React.FC<IEventsPageProps> = () => {
    return (
        <div>

        </div>
    );
};

export default EventsPage;