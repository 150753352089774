import React from 'react';

interface IContactCardProps {
    DepartmentName: string;
    EmailAddress: string;
};

const ContactCard: React.FC<IContactCardProps> = ({ DepartmentName, EmailAddress }) => {
    return (
        <div className="shadow rounded bg-white flex flex-col space-y-4">
            <h1 className="text-xl font-semibold border-b-4 border-gray-600 bg-gray-400 text-white p-4 rounded-t">{DepartmentName}</h1>
            <p className="text-md p-4">
                <a href={`mailto:${EmailAddress}`}>{EmailAddress}</a>
            </p>
        </div>
    )
};

export default ContactCard;