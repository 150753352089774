import React, { Fragment } from "react";
import ContactCard from "../components/ContactCard";

const ContactPage: React.FC<{}> = () => {
    return (
        <Fragment>
            <div className="bg-gray-300">
                <div className="container mx-auto text-2xl p-8">
                    <div>
                        How to get ahold of us.
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-8 container mx-auto p-8">
                <div>
                    <h1 className="text-3xl font-thin pb-4">Telephone</h1>
                    <address className="mx-auto container">
                        +1 519-669-2047
                    </address>
                    <p>
                        * Please note that the phone is generally not answered, but is
                        checked regularily for messages.
                    </p>
                </div>
                <div>
                    <h1 className="text-3xl font-thin pb-4">Address</h1>
                    <address className="mx-auto container">
                        6533 Reid Woods Drive<br />
                        Elmira, Ontario<br />
                        Canada
                    </address>
                </div>
                <div>
                    <h1 className="text-3xl font-thin pb-4">Mailing Address</h1>
                    <address className="mx-auto container">
                        Elmira &amp; District Rod and Gun Club<br />
                        P.O. Box 241<br />
                        Elmira, Ontario N3B 2Z6<br />
                        Canada
                    </address>
                </div>
            </div>
            <h1 className="text-3xl font-thin container mx-auto px-8 pb-8">Departments</h1>
            <div className="mx-auto container grid grid-cols-3 gap-8 px-8">
            <ContactCard DepartmentName="Archery" EmailAddress="archery@elmirarodandgun.org" />
                <ContactCard DepartmentName="Bookings and Rentals" EmailAddress="bookings@elmirarodandgun.org" />
                <ContactCard DepartmentName="Membership" EmailAddress="membership@elmirarodandgun.org" />
                <ContactCard DepartmentName="Newsletter" EmailAddress="newsletter@elmirarodandgun.org" />
                <ContactCard DepartmentName="Canagagigue Shooting Association Pistol Club" EmailAddress="pistolclub@elmirarodandgun.org" />
                <ContactCard DepartmentName="Range Safety Officer" EmailAddress="rso@elmirarodandgun.org" />
                <ContactCard DepartmentName="Sports and Recreation" EmailAddress="sportsandrec@elmirarodandgun.org" />
                <ContactCard DepartmentName="Webmaster" EmailAddress="webmaster@elmirarodandgun.org" />
            </div>
            <div className="p-8"></div>
        </Fragment>
    );
};

export default ContactPage;