import React, { Fragment } from 'react';

interface IRulesPageProps {

};

const RulesPage: React.FC<IRulesPageProps> = () => {
    return (
        <Fragment>
            <div className="bg-gray-200">
                <div className="container mx-auto p-8">
                    <h1 className="text-2xl">Club Rules</h1>
                </div>
            </div>
            <div className="mx-auto container p-8 space-y-4">
                <p>
                    As a respected member of the community, the Elmira Rod & Gun Club strives to conduct
                    itself in a safe and responsible manner at all times. As such, we have a
                    ‘One-strike and you’re out’ rule for any members who break range safety or conduct rules.
                    Be safe, use all safety equipment, appoint a Range Officer if there is more than one person
                    shooting, and always treat members and citizens with the respect that you in turn would
                    expect from others. In order to maintain a good standing with the Chief Firearm Officers
                    (CFO) it is important that all members adhere to the club rules set by its members.
                </p>
                <h2 className="text-xl">General Conduct</h2>
                <ul className="list-disc px-6">
                    <li>
                        Anyone using Club property is duly advised of the possibility of lead
                        contamination on these premises. Food & drink are not permitted on any
                        of the ranges.
                    </li>
                    <li>
                        Alcohol and other intoxicating substances are not permitted on any of the ranges,
                        nor is anyone under the influence of the same permitted to handle firearms at any time.
                    </li>
                    <li>
                        Both shooters and spectators are required to wear eye and ear protection when on range
                    </li>
                    <li>
                        Appoint a Range Officer if more than one person is shooting.
                    </li>
                    <li>
                        Ceasefires may be called by anyone on the range, including shooters and spectators.
                    </li>
                    <li>
                        Defective ammunition is not to be left on the range or disposed of in the garbage.
                        Members are to place defective ammunition in the duds container for proper and safe disposal.
                    </li>
                    <li>
                        Members are responsible for their guests at all times and must fully supervise them in any
                        and all shooting activities.
                    </li>
                    <li>
                        Guests may only visit the Club a maximum of three times; after which they may either apply to
                        join, or shoot elsewhere.
                    </li>
                    <li>
                        All members must report any range damage or misconduct to Range Officer and/or Club Executives.
                    </li>
                    <li>
                        Interactions with the Chief Firearms Office regarding any issue at this Club can only be
                        performed by the acting Range Operator who is presently recorded as main contact for this Club.
                    </li>
                    <li>
                        Members are asked to take the time to make any minor repairs such as replacing backer boards,
                        emptying garbage, trimming grass from top of bunkers
                    </li>
                </ul>
                <h2 className="text-xl">Light System</h2>
                <ul className="list-disc px-6">
                    <li>
                        All shooters are to use <em>Range in Use</em> signs and Red/Green light system where available.
                    </li>
                    <li>
                        Red light must be active whenever firearms are being handled.
                    </li>
                    <li>
                        Green light must be active before moving forward of firing line.
                    </li>
                    <li>
                        Firearms must not be handled when green light active
                    </li>
                    <li>
                        Range light circuit breaker is located in the top right fuse panel found in the basement
                        storage room (behind Pool room).
                    </li>
                </ul>
                <h2 className="text-xl">General Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        Targets must be stapled/tacked to backer boards.
                    </li>
                    <li>
                        Metallic silhouettes must be set inside bunkers.
                    </li>
                    <li>
                        Water bottles and balloons are permitted as targets but must be mounted to
                        backer boards or placed inside of bunkers.
                    </li>
                    <li>
                        Portable target holders are not permitted without prior Executive approval.
                    </li>
                    <li>
                        Human silhouette targets are not permitted.
                    </li>
                    <li>
                        All brass and targets are to be collected and disposed of after shooting.
                    </li>
                    <li>
                        When sighting in shotguns, members must bring their own cardboard to be placed
                        in front of bunkers to prevent backer boards from being destroyed.
                    </li>
                    <li>
                        Anyone caught placing targets on top of bunkers or on overhead baffles will be
                        permanently banned from Club property.
                    </li>
                    <li>
                        Prohibited class firearms as well as home-made firearms not permitted on Club property.
                        Grandfathered prohibited class pistols/revolvers are permitted.
                    </li>
                    <li>
                        Shooters using restricted class firearms must hold a valid Authorization to Transport
                        and use sign-in book located at 100 Yard range or Indoor range.
                    </li>
                    <li>
                        Air-powered firearms with a velocity of 500fps or more must comply with all CFO firearms
                        regulations regarding range use.
                    </li>
                    <li>
                        Air-powered pistols with a velocity of 500fps or more are considered a restricted class firearm.
                    </li>
                </ul>
                <h2 className="text-xl">Indoor Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        The Indoor range is sanctioned for pistol/revolver, .22LR, air-rifle and archery only.
                    </li>
                    <li>
                        If using indoor range for archery, archers are required to supply a butt-stop directly in front of backstop.
                    </li>
                    <li>
                        Archery and shooting are not to be performed simultaneously; first-come, first-serve.
                    </li>
                    <li>
                        Both doors must be locked when shooting in progress.
                    </li>
                    <li>
                        Range warning lights must be used when shooting in progress.
                    </li>
                    <li>
                        Semi-automatic firearms are limited to five rounds.
                    </li>
                    <li>
                        Jacketed bullets are not permitted.
                    </li>
                    <li>
                        Firearms must not be used forward of firing line.
                    </li>
                    <li>
                        Portable targets are not permitted.
                    </li>
                    <li>
                        When shooting is complete, all brass and targets are to be collected and properly disposed of.
                        Gloves and masks are available.
                    </li>
                </ul>
                <h2 className="text-xl">100 Yard Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        100 Yard range is sanctioned for rifles and shotguns (smokeless, black powder and compressed air) only.
                    </li>
                    <li>
                        <em>Range in Use</em> signs and Red/Green lights must be used during shooting.
                    </li>
                    <li>
                        100 Yard and 200 Yard ranges may be used simultaneously.
                    </li>
                    <li>
                        Gate between 100 Yard and 200 Yard ranges must be kept closed during shooting.
                    </li>
                    <li>
                        Firing from standing or seated positions (at benches) only; no kneeling or prone positions permitted.
                    </li>
                    <li>
                        No shooting permitted forward of firing line.
                    </li>
                </ul>
                <h2 className="text-xl">Clay-Shooting Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        Clay-shooting range is sanctioned for shotguns only.
                    </li>
                    <li>
                        When in use, Clay-shooting range must be sectioned off using rope and
                        sign located at South-East corner of Clubhouse.
                    </li>
                    <li>
                        Only one shooter at a time when using Clay-shooting range.
                    </li>
                    <li>
                        Collect and dispose of all shotshell hulls when finished shooting.
                    </li>
                </ul>
                <h2 className="text-xl">200 Yard Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        200 yard range is sanctioned for rifles, shotguns and air rifles only.
                    </li>
                    <li>
                        Rimfire ammunition is not permitted.
                    </li>
                    <li>
                        <em>Range in Use</em> signs and Red/Green light system must be used when shooting.
                    </li>
                    <li>
                        Orange 200 Yard <em>Range in Use</em> sign located at 100 Yard range must be used during shooting.
                    </li>
                    <li>
                        Shooting must be conducted from Bench Rest position only.
                    </li>
                    <li>
                        Vehicles may be used to transport equipment to 200 Yard range, but must be moved/parked
                        at 100 Yard range afterwards.
                    </li>
                    <li>
                        Air rifle shooters must have metallic silhouettes located in suitable constructed bunker
                        approved by Club Range Operator.
                    </li>
                </ul>
                <h2 className="text-xl">Archery Range Rules</h2>
                <ul className="list-disc px-6">
                    <li>
                        Currently CFO does not hold jurisdiction over Archery ranges.
                    </li>
                    <li>
                        Broadheads are not permitted on the archery range.
                    </li>
                    <li>
                        Targets have been placed in designated areas by Archery Executive and may not be moved.
                    </li>
                    <li>
                        Final 1/3 of Archery trail is closed until further notice. First 2/3 may be used up
                        to rope barrier.
                    </li>
                </ul>
                <h2 className="text-xl">The following violations will result in immediate lifetime ban from Club premises:</h2>
                <ul className="list-disc px-6">
                    <li>
                        Openly disregarding Club regulations.
                    </li>
                    <li>
                        Improper use of firearms and/or Club facilities or equipment.
                    </li>
                    <li>
                        Threats, violence or other unacceptable behavior towards members.
                    </li>
                    <li>
                        Theft of any kind.
                    </li>
                </ul>
            </div>
        </Fragment>
    );
};

export default RulesPage;