import * as React from 'react';
import bypass from '../media/85-bypass.jpg';
import bypass2 from '../media/elmira-bypass-reid-woods.jpg';
import bypass3 from '../media/northfield-bypass.jpg';

const GunShowPage = () => {
    return (
        <div className="p-8">
            <h1 className="text-3xl">2023 Annual Gun Show</h1>
            <h2 className="text-xl font-medium mt-4">Admission</h2>
            <div className="my-4">
                Show starts at 8 AM, and runs until 1 PM. Vendors can arrive at 7 AM.
            </div>
            <ul className="list-disc ml-4">
                <li>$5 for adults.</li>
                <li>Children under 12 are free.</li>
                <li>$25 per table for vendors. There may be some available on the day of the show.</li>
            </ul>
            <div className="my-4">
                Breakfast (Bacon and Cheese on a Bun), Coffee, Tea and other snacks will be available.
            </div>
            <div className="my-4">
                The facility is wheelchair accessible. There are no ATMs on site, and limited cell reception. 
                Computers will be provided for transfers.
            </div>
            <h2 className="text-xl font-medium mt-4">Important Information</h2>
            <p className="py-2">Due to road closures, access to the Elmira &amp; District Rod and Gun Club is limited. Please follow the instructions below to access the club.</p>
            <ul className="space-y-2">
                <li className="border-red-500 bg-red-50 p-2 border">Line 86 is closed at the intersection of 86 and Floradale Road. Use Highway 85 to bypass.</li>
                <li className="border-red-500 bg-red-50 p-2 border">Line 86 (Chruch Street) is closed at the bridge at Bolender Park in Elmira. Use Arthur Street to access Highway 85 or Reid Woods Drive directly.</li>
            </ul>
            <div className="lg:grid lg:grid-cols-2">
                <div>
                    <h3 className="text-lg font-medium my-4">Accessing the club from Waterloo/St. Jacobs</h3>
                    <img src={bypass} />
                    <h4 className="my-4 font-medium">Directions</h4>
                    <ul className="list-disc ml-4">
                        <li>From Highway 85 (from Waterloo), turn left at Highway 86 (Crossroads Mercantile is on the left).</li>
                        <li>Take Highway 86 to Line 86 and turn right.</li>
                        <li>Turn left at Reid Woods Drive.</li>
                        <li>Turn right at 3 Line.</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-lg font-medium my-4">Accessing the club from north of Elmira (Floradale/Elora)</h3>
                    <img src={bypass2} />
                    <h4 className="my-4 font-medium">Directions</h4>
                    <ul className="list-disc ml-4">
                        <li>Reid Woods Drive is accessible from Floradale Road (Arthur Street).</li>
                        <li>Take Reid Woods Drive to the corner of Reid Woods Drive and 3 Line.</li>
                        <li>At the stop sign, turn left.</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-lg font-medium my-4">Accessing the club from Guelph</h3>
                    <img src={bypass3} />
                    <h4 className="my-4 font-medium">Directions</h4>
                    <em>Option 1</em>
                    <ul className="list-disc ml-4">
                        <li>Turn right at Line 86 and Northfield Drive.</li>
                        <li>Turn left at Sandy Hills Drive.</li>
                        <li>Turn left at Reid Woods Drive.</li>
                        <li>Take Reid Woods Drive to 3 Line.</li>
                        <li>At the stop sign, turn left.</li>
                    </ul>
                    <em className="mt-4 block">Option 2</em>
                    <ul className="list-disc ml-4">
                        <li>Turn left at Line 86 and Northfield Drive.</li>
                        <li>Turn right at Sawmill Road.</li>
                        <li>At the roundabout, take the first exit (turn right).</li>
                        <li>Turn left at Highway 86.</li>
                        <li>Turn right at Line 86.</li>
                        <li>Turn left at Reid Woods Drive.</li>
                        <li>Turn right at 3 Line.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default GunShowPage;