import React, { Fragment } from 'react';

interface IHelpPageProps {

};

const HelpPage: React.FC<IHelpPageProps> = () => {
    return (
        <Fragment>
            <div className="bg-gray-200">
                <div className="container mx-auto p-8">
                    <h1 className="text-2xl">Help</h1>
                </div>
            </div>
            <div className="mx-auto container p-8 space-y-4">
                <h2 className="text-xl">Fob Issues</h2>
                <div>
                    <h3 className="font-bold">My fob doesn't light up or change colour when I insert it in to a station.</h3>
                    <p>
                        Your fob is most likely not charged. Using a USB cable connected to a port on a computer or
                        a USB charging brick, connect your fob with the included charging adapter. The fob should
                        flash red while it's charging, and once it's complete, hold a steady green light.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">My fob turns red but not green when docking or at a station.</h3>
                    <p>
                        Your fob is most likely not updated. You can visit one of the two fob update stations located at the
                        clubhose - one inside the main doors, the other directly outside the main doors. Insert the key and
                        wait for the light to turn green.
                    </p>
                </div>
            </div>
            <div className="mx-auto container p-8 space-y-4">
                <h2 className="text-xl">Newsletter Issues</h2>
                <div>
                    <h3 className="font-bold">I am not receiving a newsletter.</h3>
                    <p className="mb-4">
                        There are many reasons that you may not be receiving a newsletter, sometimes these are out of our
                        control. All members are asked to provide their email address during registration/renewal. If you
                        have not provided your email address, please contact&nbsp;
                        <a className="underline" href="mailto:membership@elmirarodandgun.org">membership@elmirarodandgun.org</a> to update your
                        membership details.
                    </p>
                    <p>
                        If your information is correct at the time you registered/renewed, you can attempt one of the following
                        steps:
                    </p>
                    <ul className="space-y-4 p-4">
                        <li>
                            Check your spam folder for any emails from the club. If they are ending up in your Spam Folder, 
                            make sure to mark them as "Not Spam".
                        </li>
                        <li>
                            Add the&nbsp;<span className="font-semibold">elmirarodandgun.org</span> and&nbsp;
                            <span className="font-semibold">e.elmirarodandgun.org</span> domains to your email provider's Safe
                            Sender/Whitelist.
                        </li>
                        <li>
                            Contact <a className="underline" href="mailto:webmaster@elmirarodandgun.org">webmaster@elmirarodandgun.org</a>&nbsp;
                            with an alternate email address we can use.<br/>
                            <span className="font-semibold">
                                Email addresses from providers such as Hotmail are strongly discouraged, as they heavily block
                                our domain even after we've done a lot of work to fix these problems.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default HelpPage;