import React, { Fragment } from 'react';

const RenewalConfirmPage: React.FC<{}> = () => {
    return (
        <Fragment>
            <div className="mx-auto container rounded bg-white my-8 p-4 shadow space-y-4">
                <div className="mb-4 mt-4 border-b pb-4">
                    <div className="font-semilight text-3xl">2021 Membership Renewal</div>
                </div>
                <div>
                    Thank you for submitting your renewal application.
                </div>
                <div className="text-lg">
                    You will receive an email within the next 24 hours confirming that your renewal application
                    was successfully received. If you don't receive it, please contact us.
                </div>
                <div>
                    If you are paying in Cash, you must attend one of the following in-person events
                    in order to make payment.
                </div>
                <ul>
                    <li>December 4<sup>th</sup> from 1PM to 3PM</li>
                    <li>December 14<sup>th</sup> around the meeting</li>
                    <li>December 30<sup>th</sup> from 6PM to 9PM</li>
                </ul>
                <div>
                    If you are paying with Interac eTransfer, add&nbsp;<span className="font-semibold">dues@elmirarodandgun.org</span>
                    &nbsp;to your payees in you bank's online banking platform and submit your payment.
                </div>
                <div>
                    If you are paying by cheque or money order, make it payable to:
                </div>
                <address>
                    Elmira and District Rod and Gun Club<br />
                    PO Box 241<br />
                    Elmira, Ontario N3B 2Z6
                </address>
                <div>
                    If your payment is not received by December 31<sup>st</sup>, 2021, you will incur late fees.
                </div>
            </div>
        </Fragment>
    )
};

export default RenewalConfirmPage;