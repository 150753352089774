import React, { Fragment } from 'react';

interface IJoinPageProps {

};

const JoinPage: React.FC<IJoinPageProps> = () => {
    return (
        <Fragment>
            <div className="bg-gray-300">
                <div className="container mx-auto text-2xl p-8">
                    <div>
                        Join one of the largest shooting sports clubs in Waterloo Region.
                    </div>
                </div>
            </div>
            <div className="mx-auto container p-8 text-center">
                <p className="text-3xl">Membership is Currently Closed</p>
                <p>We are not accepting applications at this time.</p>
            </div>
            <div className="mx-auto container p-8">
                Elmira District Rod &amp; Gun Club prides itself on being not just a shooting range,
                but a club for firearms and archery enthusiasts. We have some of the lowest club dues
                in all of southern Ontario, while offering some of the best and most diverse facilities.
                In order to become a full member of the Elmira District Rod &amp; Gun Club you will be
                required to complete our application process.
            </div>
            <div className="grid xs:grid-cols-1 md:grid-cols-3 gap-8 mx-auto container p-8">
                <div>
                    <span className="first-char">1</span>
                    <h1 className="text-xl">Join the Club</h1>
                    <p>
                        The first step to joining is to attend a ‘First Meeting.’ Our club meets on the second
                        Tuesday of every month at 8:00PM, sharp. Plan to arrive 15-30 minutes early, to meet the
                        Membership Chair and declare your intention to join. At this time you will submit an
                        Application form, (ensure this is completed fully before attending). After receiving your application,
                        the Membership Chair will provide you with a Volunteer/Shooting Events form.
                    </p>
                </div>
                <div>
                    <span className="first-char">2</span>
                    <h1 className="text-xl">First Meeting</h1>
                    <p>
                        Once the meeting begins, the Executive will move through scheduled business, after which new applicants
                        will be asked to stand and introduce themselves to the membership. Generally this is very brief and informal.
                        All new members must also be sponsored by an existing member in good standing, so if you haven’t found one yet,
                        these meetings are a great time to do so.
                    </p>
                </div>
                <div>
                    <span className="first-char">3</span>
                    <h1 className="text-xl">Volunteer Hours</h1>
                    <p>
                        After your name is entered into the meeting minutes, you will be required to attend at least two club events or
                        functions to complete your volunteer hours. Volunteer opportunities include things like club maintenance or work
                        parties, competitions and other designated events.  This is a mandatory step, and all volunteer hours must be
                        completed within a year of your application, or you will be required to restart the process from the beginning.
                    </p>
                </div>
                <div>
                    <span className="first-char">4</span>
                    <h1 className="text-xl">Police Check</h1>
                    <p>
                        Once you have completed your volunteer/shooting events you will be required to obtain a police check from your
                        local police station (the standard one is sufficient), a nominal fee will be charged at your local station to
                        receive your police check. Please contact your local police for information on the cost of this service as we
                        do not maintain pricing info.
                    </p>
                </div>
                <div>
                    <span className="first-char">5</span>
                    <h1 className="text-xl">Second Meeting</h1>
                    <p>
                        With your volunteer hours completed and police check in hand, you will attend your ‘Second Meeting.’ Once again,
                        plan to arrive 15-30 minutes early to speak with the Membership Chair, at which time you will submit your
                        paperwork and police check. During the meeting a vote will be held to admit new members on a probationary basis.
                        At the meeting’s conclusion, you will see the Range Safety Officer (RSO) to schedule a range
                        orientation.
                    </p>
                </div>
                <div>
                    <span className="first-char">6</span>
                    <h1 className="text-xl">Range Orientation</h1>
                    <p>
                        All new members are required to attend an orientation of our facilities. This includes instructions on how to use the
                        gates, key FOBS and various ranges/equipment. The club Range Safety Officer (RSO) schedules regular orientations for new
                        members, at his or her convenience. Upon successful completion of your orientation you will then pay your dues, your
                        initiation fee and FOB deposit. Then you will be issued your FOB
                    </p>
                </div>
            </div>
            <div className="container mx-auto p-8">
                The membership process can sound intimidating, however dedicated applicants can often complete it in just a couple months.
                All though it may seem onerous at times, this system helps to ensure all members are responsible, law-abiding gun owners
                committed to following the rules and representing the shooting sports community in a mature and positive manner. At the
                end of the day, if this sounds like too much work, this probably isn’t the club for you.
            </div>
            <div className="container mx-auto p-8 xs:flex-col xs:space-y-8 md:flex-row md:space-x-8">
                <a className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded xs:w-full md:w-96 space-x-4 inline-flex"
                    href="https://files.elmirarodandgun.org/2024%20Membership%20Form.pdf">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                    <div className="flex flex-col">
                        <span className="font-semibold">Download Application Form</span>
                        <span className="font-normal">2024 Membership Form.pdf</span>
                    </div>
                </a>
            </div>
        </Fragment>
    )
};

export default JoinPage;