import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC<{}> = () => {
    return (
        <footer className="bg-gray-600 p-4 space-y-4 text-sm text-white">
            <div className="md:grid md:grid-cols-3 md:gap-4 xs:space-y-4 md:container md:mx-auto">
                <section>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    6533 Reid Woods Drive<br />
                    Elmira, Ontario
                </section>
                <section>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                    </svg>                    
                    Elmira &amp; District Rod and Gun Club<br />
                    PO Box 241<br />
                    Elmira, Ontario N3B 2Z6
                </section>
                <section>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    1-519-669-2047
                </section>
            </div>
            <div className="md:container md:mx-auto md:flex md:justify-between">
                <Link to="/contact">Contact Us</Link>
                <Link to="/privacy">Privacy Policy</Link>
            </div>
            <div className="md:flex md:flex-row flex-col justify-between md:container md:mx-auto xs:space-y-4">
                <div>&copy; 2021 Elmira &amp; District Rod and Gun Club. All rights reserved.</div>
            </div>
        </footer>
    );
}

export default Footer;