import React, { Fragment } from 'react';

enum Numbers {
    "No",
    "One",
    "Two",
    "Three"
}

interface IHealthBannerProps {
    mustHaveMask?: boolean;
    maxGuests?: number;
    maxIndoor?: number;
    maxOutdoor?: number;
    areIndoorRangesOpen: boolean;
    areRangesOpen: boolean;
    isMeetingSpaceOpen?: boolean;
    meetingSpaceLimit?: number;
};

const HealthBanner: React.FC<IHealthBannerProps> = ({ mustHaveMask, maxGuests, areIndoorRangesOpen, areRangesOpen, maxIndoor, maxOutdoor, isMeetingSpaceOpen, meetingSpaceLimit }) => {
    return (
        <div className="bg-white dark:bg-gray-900 rounded shadow opacity-90">
            <div className="p-4 xs:p-2 bg-green-200 border-b rounded-t opacity-100">
                <h1 className="md:text-xl font-semibold xs:text-lg">Health and Safety Information</h1>
            </div>
            <div className="p-4 xs:p-2 space-y-2">
                <p className="text-xl font-semibold border-b pb-4 hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2 float-left" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span>
                        Boil Water Advisory in effect.
                    </span>
                </p>
                <p className="hidden">
                    In accordance with the Government of Ontario and the various orders surrounding the COVID-19 pandemic, the following restrictions are in place:
                </p>
                <p>
                    There are no current health and safety notices.
                </p>
                <div className="md:grid md:grid-cols-2">
                    <ul className="list-none hidden">
                        <li>Outdoor ranges are <span className="font-semibold uppercase">{areRangesOpen ? "open" : "closed"}</span>;</li>
                        <li>Indoor ranges are&nbsp;
                            <span className="font-semibold uppercase">
                                {areIndoorRangesOpen && (maxIndoor !== undefined ? `limited capacity (${maxIndoor})` : "open")}
                                {!areIndoorRangesOpen && "closed"}
                            </span>;
                        </li>
                        {isMeetingSpaceOpen &&
                            <li>Indoor hall is <span className="font-semibold uppercase">open</span> 
                            {meetingSpaceLimit && <Fragment> to <span className="font-semibold uppercase">{meetingSpaceLimit}%</span> of capacity</Fragment>};</li>
                        }
                        {!isMeetingSpaceOpen &&
                            <li>Indoor hall is <span className="font-semibold uppercase">closed</span>;</li>
                        }
                        {areRangesOpen &&
                            <li>Indoor washroom facilities are available;</li>
                        }
                    </ul>
                    <ul className="list-none hidden">
                        {mustHaveMask && <li>Masks must be worn while indoors or physical distancing is not possible;</li>}
                        {maxGuests &&
                        <li><strong>{Numbers[maxGuests]}</strong> guest{maxGuests === 1 ? " is" : "s are"} allowed;</li>
                        }
                        <li>Only odd shooting stations are allowed to be used.</li>
                    </ul>
                </div>
            </div>
            <div className="p-4 xs:p-2 space-y-2 border-t hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 float-left" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <span className="font-semibold">Remember:</span> Even if you're fully vaccinated, you must comply with these regulations at all times while at our facility.
            </div>
        </div>);
};

export default HealthBanner;