import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import HealthBanner from '../components/HealthBanner';
import AnnoucementsCard from '../components/AnnoucementsCard';
import CalendarCard from '../components/CalendarCard';
import hero from '../media/hero2.jpg';

interface IHomePageProps {

};

const HomePage: React.FC<IHomePageProps> = () => {
    return (
        <Fragment>
            <div className="p-8 md:pt-36 xl:pt-48" style={{ backgroundAttachment: "fixed", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: "url(" + hero + ")" }}>
                <div className="md:container md:mx-auto md:grid md:grid-cols-3 md:gap-4">
                    <div className="md:col-span-2 md:mt-64">
                    </div>
                </div>
            </div>
            <div className="bg-gray-900 text-white md:text-lg">
                <div className="container mx-auto p-8 space-y-4">
                    <h1 className="xs:text-xl md:text-3xl">Welcome</h1>
                    <p>
                        The Elmira &amp; District Rod &amp; Gun Club provides a safe and inviting set of facilities for multiple
                        disciplines such as bench rest shooting, clay targets and archery.
                    </p>
                    <p>
                        We are a members-only organization, boasting one of the largest shooting facilities in Waterloo region.
                    </p>
                </div>
            </div>
            <div className="md:grid md:grid-cols-2 xl:grid-cols-3 md:gap-4 md:container md:mx-auto p-8">
                <div className="xl:col-span-2 p-3 space-y-4">
                    <div className="text-2xl">
                        Important Information
                    </div>
                    <div className="border-2 border-yellow-200 bg-yellow-100 p-2 space-y-4" style={{ marginLeft: "-4px", marginRight: "-4px" }}>
                        <h3 className="font-semibold text-xl">Important Membership Renewal Information</h3>
                        <div>
                            At the 2022 Annual General Meeting, membership and executive agreed to move club membership renewals to the month of
                            April. Renewals are to be received no later than April 30th.
                        </div>
                    </div>
                    <div className="xl:grid xl:grid-cols-2 xl:gap-4 xs:flex xs:flex-col xs:space-y-4">
                        <div className="space-y-2">
                            <h1 className="text-xl font-semibold">Looking to join?</h1>
                            <p>
                                We are currently at capacity at this time, and <span className="font-semibold">not accepting</span> any applications
                                for new members. We recommend interested prospective new members check our website for updates closer to the end of
                                the year.
                            </p>
                        </div>
                        <div className="space-y-2">
                            <h1 className="text-xl font-semibold">Membership Renewal</h1>
                            <p>
                                <strong>
                                    Membership renewals are now closed.
                                </strong>
                            </p>
                            <p className="hidden">
                                <strong>Renewals are now open.</strong><br />
                                Please do not send Interac eTransfers without first being instructed to do so.
                            </p>
                            <p className="hidden">
                                <strong>Online renewals are not available this year.</strong>
                                <p>All renewals must be completed in person. Please review the calendar for available dates.</p>
                            </p>
                            <p>
                                <strong>Fee Increase</strong><br />
                                Fees have increased to $250 for Individual and $300 for Family dues.
                            </p>
                            <a className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded xs:w-full md:w-96 space-x-4 inline-flex hidden"
                                href="https://files.elmirarodandgun.org/2024%20Membership%20Renewal%20Form.pdf">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                <div className="flex flex-col">
                                    <span className="font-semibold">Download Renewal Form</span>
                                    <span className="font-normal">2024 Membership Renewal Form.pdf</span>
                                </div>
                            </a>
                            <a className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded xs:w-full md:w-96 space-x-4 inline-flex"
                                href="https://files.elmirarodandgun.org/2024%20Volunteer%20Form.pdf">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                <div className="flex flex-col">
                                    <span className="font-semibold">Volunteer Form</span>
                                    <span className="font-normal">2024 Volunteer Form.pdf</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <h1 className="text-xl font-semibold">Project Mapleseed</h1>
                        <p>
                            We will be hosting Project Mapleseed, on Sunday June 9th. For more information, click on the link below to download the brochure.
                        </p>
                        <a className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded xs:w-full md:w-96 space-x-4 inline-flex"
                            href="https://files.elmirarodandgun.org/Project%20Mapleseed%20Information%20Brochure.pdf">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                            <div className="flex flex-col">
                                <span className="font-semibold">Download Brochure</span>
                                <span className="font-normal">Project Mapleseed Information Brochure.pdf</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="md:space-y-4 xs:space-y-2">
                    <HealthBanner areRangesOpen={true} areIndoorRangesOpen={true} isMeetingSpaceOpen={true} />
                    <AnnoucementsCard />
                    <CalendarCard />
                </div>
            </div>
        </Fragment>
    );
};

export default HomePage;