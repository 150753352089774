import React, { Fragment, useEffect, useState } from 'react';
import GoogleCalendar from '../media/GoogleCalendar.svg';
import axios from 'axios';

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const monthsFull = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

interface ICalendarCardProps {

};

interface ICalendarItem {
    id: string;
    name: string;
    start: Date;
    end: Date;
};

const CalendarCard: React.FC<ICalendarCardProps> = () => {

    const [calendarItems, setCalendarItems] = useState<ICalendarItem[]>([]);
    const [nextMeeting, setNextMeeting] = useState<ICalendarItem | undefined>(undefined);
    const [nextMeetingDate, setNextMeetingDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        axios.get<ICalendarItem[]>('/api/v1/calendar')
            .then((resp) => {
                setCalendarItems(resp.data);
            });

        axios.get<ICalendarItem>('/api/v1/calendar/7b4a562iddeufqq1372ic8uo7u/next')
            .then((resp) => {
                setNextMeeting(resp.data);
                setNextMeetingDate(new Date(resp.data.start));
            });
    }, []);

    return (
        <div className="bg-white dark:bg-gray-900 rounded shadow">
            <div className="p-4 xs:p-2 border-b">
                <h1 className="md:text-xl font-semibold xs:text-md">Calendar</h1>
            </div>
            <div className="p-4 xs:p-2 border-b">
                <a
                    className="bg-blue-500 hover:bg-blue-400 text-white border-blue-600 hover:border-blue-500 border-b-4 rounded px-4 py-2 flex flex-row space-x-2"
                    target="_blank" rel="noreferrer"
                    href="https://calendar.google.com/calendar/embed?src=c_v4r18b7c471ev763eu55pipohk%40group.calendar.google.com&ctz=America%2FToronto">
                    View Full Calendar
                </a>
            </div>
            <div>
                <div className="text-lg font-semibold px-4 py-2">Next Meeting</div>
                <div className="grid grid-cols-2 gap-2 border-b border-t px-4 py-2">
                    {nextMeeting !== undefined &&
                        <Fragment>
                            <div className="flex flex-col">
                                <span className="text-gray-800 font-semibold">Date</span>
                                <span>{monthsFull[nextMeetingDate?.getMonth() ?? 0]} <span className="ordinal tubular-nums">{nextMeetingDate?.getDate()}</span>, 2021</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-gray-800 font-semibold">Time</span>
                                <span>{(nextMeetingDate?.getHours() ?? 0) > 12 ? (nextMeetingDate?.getHours() ?? 0) - 12 : nextMeetingDate?.getHours()}:{nextMeetingDate?.getMinutes().toString().padStart(2, '0')} PM EST</span>
                            </div>
                            <div className="col-span-2">
                                <a
                                    href="https://calendar.google.com/event?action=TEMPLATE&tmeid=N2I0YTU2MmlkZGV1ZnFxMTM3MmljOHVvN3VfMjAyMTA3MTRUMDAwMDAwWiBjX3Y0cjE4YjdjNDcxZXY3NjNldTU1cGlwb2hrQGc&tmsrc=c_v4r18b7c471ev763eu55pipohk%40group.calendar.google.com&scp=ALL"
                                    target="_blank" rel="noreferrer"
                                    className="bg-blue-500 hover:bg-blue-400 text-white border-blue-600 hover:border-blue-500 border-b-4 rounded px-4 py-2 flex flex-row space-x-2">
                                    <img src={GoogleCalendar} className="h-6" alt="" />
                                    <span>Add to your Google Calendar</span>
                                </a>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
            <div>
                <div className="text-lg font-semibold px-4 py-2 border-t">Events</div>
                <div className="px-4 py-2 border-t flex flex-col">
                    {calendarItems.map((item, index) => {
                        if (item.id === "7b4a562iddeufqq1372ic8uo7u") {
                            return false;
                        }

                        const start = new Date(item.start);
                        const end = new Date(item.end);
                        return (<div key={index} className="flex flex-row space-x-4">
                            <div className="flex-grow font-semibold text-lg capitalize">{item.name}</div>
                            <div className="leading-7">{months[start.getMonth()]} {start.getDate()}{(end.getDate() > start.getDate() ? '-' + end.getDate() : '')}</div>
                        </div>);
                    })}
                </div>
            </div>
        </div>
    );
};

export default CalendarCard;