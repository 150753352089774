import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import RenewalConfirmPage from './pages/RenewalConfirmPage';
import ContactPage from './pages/ContactPage';
import EventsPage from './pages/EventsPage';
import FacilitiesPage from './pages/FacilitiesPage';
import JoinPage from './pages/JoinPage';
import MembersPage from './pages/MembersPage';
import PistolClubPage from './pages/PistolClubPage';
import HelpPage from './pages/HelpPage';
import RulesPage from './pages/RulesPage';
import GunShowPage from './pages/GunShowPage';

function App() {
    return (
        <Router>
            <Header />
            <div className="flex-grow flex-col z-0">
                <Switch>
                    <Route path="/events">
                        <EventsPage />
                    </Route>
                    <Route path="/facilities">
                        <FacilitiesPage />
                    </Route>
                    <Route path="/members">
                        <MembersPage />
                    </Route>
                    <Route path="/join">
                        <JoinPage />
                    </Route>
                    <Route path="/contact">
                        <ContactPage />
                    </Route>
                    <Route path="/pistol">
                        <PistolClubPage />
                    </Route>
                    <Route path="/renewConfirm">
                        <RenewalConfirmPage />
                    </Route>
                    <Route path="/rules">
                        <RulesPage />
                    </Route>
                    <Route path="/calendar">
                        <></>
                    </Route>
                    <Route path="/gunshow">
                        <GunShowPage />
                    </Route>
                    <Route path="/help">
                        <HelpPage />
                    </Route>
                    <Route path="/">
                        <HomePage />
                    </Route>
                </Switch>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
